import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-colorado-springs-colorado.png'
import image0 from "../../images/cities/scale-model-of-springs-bike-tours-in-colorado-springs-colorado.png"
import image1 from "../../images/cities/scale-model-of-pikes-peak-region-attractions-in-colorado-springs-colorado.png"
import image2 from "../../images/cities/scale-model-of-magic-town-in-colorado-springs-colorado.png"
import image3 from "../../images/cities/scale-model-of-ghost-town-museum-in-colorado-springs-colorado.png"
import image4 from "../../images/cities/scale-model-of-battlefield-colorado-in-colorado-springs-colorado.png"
import image5 from "../../images/cities/scale-model-of-the-greatest-escape-games-in-colorado-springs-colorado.png"
import image6 from "../../images/cities/scale-model-of-colorado-springs-pioneers-museum-in-colorado-springs-colorado.png"
import image7 from "../../images/cities/scale-model-of-u.s.-olympic-training-center-in-colorado-springs-colorado.png"
import image8 from "../../images/cities/scale-model-of-colorado-springs-fine-arts-center-at-colorado-college-in-colorado-springs-colorado.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Colorado Springs'
            state='Colorado'
            image={image}
            lat='38.8338816'
            lon='-104.8213634'
            attractions={ [{"name": "Springs Bike Tours", "vicinity": "Downtown, Colorado Springs", "types": ["point_of_interest", "establishment"], "lat": 38.83479, "lng": -104.821685}, {"name": "Pikes Peak Region Attractions", "vicinity": "1763 S 8th St Suite 2, Colorado Springs", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 38.8066956, "lng": -104.84019219999999}, {"name": "Magic Town", "vicinity": "2418 W Colorado Ave, Colorado Springs", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.8469687, "lng": -104.86193409999998}, {"name": "Ghost Town Museum", "vicinity": "400 S 21st St, Colorado Springs", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.8404749, "lng": -104.86077190000003}, {"name": "Battlefield Colorado", "vicinity": "120 Costilla St, Colorado Springs", "types": ["store", "point_of_interest", "establishment"], "lat": 38.8286281, "lng": -104.8284132}, {"name": "The Greatest Escape Games", "vicinity": "801 S Tejon St, Colorado Springs", "types": ["point_of_interest", "establishment"], "lat": 38.8228897, "lng": -104.82374279999999}, {"name": "Colorado Springs Pioneers Museum", "vicinity": "215 S Tejon St, Colorado Springs", "types": ["museum", "point_of_interest", "establishment"], "lat": 38.830359, "lng": -104.82282700000002}, {"name": "U.S. Olympic Training Center", "vicinity": "1 Olympic Plaza, Colorado Springs", "types": ["point_of_interest", "establishment"], "lat": 38.839417, "lng": -104.79570130000002}, {"name": "Colorado Springs Fine Arts Center at Colorado College", "vicinity": "30 W Dale St, Colorado Springs", "types": ["art_gallery", "museum", "store", "point_of_interest", "establishment"], "lat": 38.8455063, "lng": -104.82621460000001}] }
            attractionImages={ {"Springs Bike Tours":image0,"Pikes Peak Region Attractions":image1,"Magic Town":image2,"Ghost Town Museum":image3,"Battlefield Colorado":image4,"The Greatest Escape Games":image5,"Colorado Springs Pioneers Museum":image6,"U.S. Olympic Training Center":image7,"Colorado Springs Fine Arts Center at Colorado College":image8,} }
       />);
  }
}